import { Eiendom, Segment } from "./Eiendom";
import { Linker } from "./Linker";

export const enum KontraktStatusId {
  UnderBehandling = 1,
  TransportertKontrakt = 2,
  TidsbestemtKontrakt = 3,
  NullKontrakt = 4,
  LopendeKontrakt = 5,
  ForlengetKontrakt = 6,
  OppsagtKontrakt = 7,
  OverfortTilBydel = 8,
  OverfortTilEby = 9,
  UtgattKontrakt = 10
}

export interface Kontrakt {
  id: string;
  tittel: string;
  eiendomId: string;
  eiendom?: Eiendom;
  segmentId: number;
  segment?: Segment;
  organisasjonsnummer: string;
  fodselsnummer: string;
  kundenummer: string;
  kontraktTypeId: number | string;
  kontraktType?: BetalingTermin;
  kontraktStatusId: KontraktStatusId;
  kontraktStatus?: BetalingTermin;
  linker?: Linker;
  arkivSakNummer: string;
  faktureringTypeId: number | string;
  faktureringType?: BetalingTermin;
  faktureringTidspunktId: number | string;
  faktureringTidspunkt?: BetalingTermin;
  betalingTerminId: number;
  betalingTermin?: BetalingTermin;
  gyldigFraDato: string;
  signertDato: string;
  startLeieDato: string;
  utlopDato: string;
  garantiDato: string;
  garantiType?: null;
  garantiTypeId: number;
  garantiNummer: string;
  depositumKonto: string;
  deresRef: string;
  notat: string;
  tilpassetBetalingManed1: number;
  tilpassetBetalingManed2: number;
  leietakerNavn: string;
  leietakerAdresse: string;
  leietakerPostnummer: string;
  leietakerPoststed: string;
  kontaktpersonNavn: string;
  kontaktpersonTelefon: string;
  kontaktpersonEpost: string;
  fakturaAdresse: null;
  fakturaPostnummer: string;
  fakturaPoststed: string;
  fakturaTekst: string;
  pris2: number;
  pris: number;
  koststedId: number | string;
  koststed?: any;
  sistIndeksregulertDato: null;
  ansvarlig: string;
  prisTypeId: number;
  prisType?: any;
  sesongStartDato: string;
  sesongSluttDato: string;
  fornyes: boolean;
  leieObjekter?: KontraktLeieObjekt[];
  fakturaer?: any[];
  // fakturaStatus: null;
  kommentarer?: any[];
  opprettetDato: string;
  sistEndretDato: string;
  opprettetAv: string;
  opprettetAvNavn: string;
  sistEndretAv: string;
  sistEndretAvNavn: string;
  kontraktMalId?: number | string;
  kontraktMal?: BetalingTermin;
  saeravtaleGrunn?: string;
  prisEndring?: PrisEndring;
  indeksjusteringPlan: IndeksjusteringPlan;
}

export interface KontraktLeieObjekt {
  leieObjektId: string;
  navn: string;
  pris?: number;
  pris2?: number;
  fakturaTekst?: string;
  antall: number;
  checked: boolean;
  timeLeie?: any[];
  prisEndring?: PrisEndring;
}

export interface BetalingTermin {
  id: number;
  navn: string;
  slettet: boolean;
  opprettetDato: string;
  sistEndretDato: string;
  opprettetAv: string;
  opprettetAvNavn: string;
  sistEndretAv: string;
  sistEndretAvNavn: string;
  faktureres?: boolean;
  segmenter?: Segment[];
}

export interface BeregnFakturaBelop {
  fakturaBelop: BeregnFakturaBelopManed[];
  beregnetBelopNesteAar: number;
}

export interface BeregnFakturaBelopManed {
  maaned: string;
  belop: number;
}

export interface NesteFakturering {
  faktureringFerdigDenneManed: boolean;
  nesteFakturering: string;
}

export interface PrisEndring {
  pris: number;
  pris2: number;
  gjelderFraDato: string;
  opprettetDato: string;
}

export enum IndeksjusteringPlan {
  // Blir indeksjustert desember første året i kontraktens gyldighetsperiode og vil være gjeldende fra januar.
  // Kontrakter som er forskudd, får en prisendring umiddelbart i desember men de som er etterskuddsvis vil få en prisendring fra og med 1 januar
  fraFoersteAar = 1,
  // Blir ikke indeksjustert første året i kontraktens gyldighetsperiode. Indeksjusteringen skjer i desember år 2 og pris blir endret umiddelbart uavhengig om forskudd/etterskudd
  fraAndreAar = 2
}
