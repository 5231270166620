import { kontraktStatus } from "constants/kontrakt";
import { Kontrakt, BeregnetFakturaBelop } from "interfaces";
import { includes } from "lodash-es";
import moment from "moment";
import { useState } from "react";
import { Alert, Button, Glyphicon } from "react-bootstrap";
import "./FakturaBeregning.scss";

interface FakturaBeregningProps {
  kontrakt: Kontrakt;
  beregnetFakturaBelop?: BeregnetFakturaBelop;
}

const endOfNextYear = new Date(new Date().getFullYear() + 1, 11, 31);
const beginningOfNextYear = new Date(new Date().getFullYear() + 1, 0, 1);

const FakturaBeregning = ({ beregnetFakturaBelop, kontrakt }: FakturaBeregningProps) => {
  const [showBeregnetFakturaBelopNesteAar, setShowBeregnetFakturaBelopNesteAar] = useState(false);

  const isPeriodeKontraktThatEndsNextYear = () =>
    includes(
      [kontraktStatus.tidsbestemtKontrakt, kontraktStatus.forlengetKontrakt],
      Number(kontrakt.kontraktStatusId)
    ) &&
    !!kontrakt.utlopDato &&
    moment(kontrakt.utlopDato).isBefore(moment(endOfNextYear)) &&
    moment(kontrakt.utlopDato).isAfter(moment(beginningOfNextYear));

  if (!beregnetFakturaBelop) return <></>;

  return (
    <Alert className="beregnetFakturaBelop">
      {beregnetFakturaBelop.fakturaBelop.length > 0 && (
        <>
          <strong>
            Følgende beregnet fakturaer vil bli generert ut {new Date().getFullYear()}. Dette er basert på valgene som
            er gjort i kontrakten
          </strong>
          {beregnetFakturaBelop.generellInfo && (
            <div className="generellInfo">
              <Glyphicon glyph="info-sign" />
              {beregnetFakturaBelop.generellInfo}
            </div>
          )}
          <div className="detaljer">
            {beregnetFakturaBelop.fakturaBelop.map(maaned => (
              <div className="detaljelinje" key={maaned.maaned}>
                <div style={{ width: "100px" }}>{maaned.maaned}</div>
                <div style={{ width: "100px" }}>
                  {Intl.NumberFormat("nb-NO", { style: "currency", currency: "NOK" }).format(maaned.belop)}
                </div>
                <div>
                  {maaned.forklaring && (
                    <span className="forklaring">
                      <Glyphicon glyph="info-sign" />
                      {maaned.forklaring}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div>
            <div style={{ width: "100px", display: "inline-block" }}>
              <strong>Sum</strong>
            </div>
            <div style={{ width: "100px", display: "inline-block" }}>
              <strong>
                {Intl.NumberFormat("nb-NO", { style: "currency", currency: "NOK" }).format(
                  Math.round(beregnetFakturaBelop.fakturaBelop.reduce((total, m) => total + m.belop, 0))
                )}
              </strong>
            </div>
          </div>
        </>
      )}
      {beregnetFakturaBelop.fakturaBelop.length === 0 && (
        <div>
          <b>Ut fra valgene i kontrakten vil det ikke bli generert noen fakturaer i {new Date().getFullYear()}</b>
        </div>
      )}
      {beregnetFakturaBelop.beregnetBelopNesteAar > 0 && (
        <>
          <div style={{ marginTop: "2em" }}>
            {isPeriodeKontraktThatEndsNextYear() && (
              <span>
                Beregnet total fakturabeløp i {new Date().getFullYear() + 1} til og med{" "}
                <b>{moment(kontrakt.utlopDato).format("DD.MM.YYYY")}</b>:{" "}
              </span>
            )}
            {!isPeriodeKontraktThatEndsNextYear() && (
              <span>
                Beregnet total fakturabeløp for hele <b>{new Date().getFullYear() + 1}</b>:{" "}
              </span>
            )}
            {Intl.NumberFormat("nb-NO", { style: "currency", currency: "NOK" }).format(
              beregnetFakturaBelop.beregnetBelopNesteAar
            )}
          </div>
          <div style={{ marginTop: "0.5em" }}>
            <Button
              style={{ padding: "0px", color: "#31708f" }}
              bsStyle="link"
              onClick={() => setShowBeregnetFakturaBelopNesteAar(prev => !prev)}
            >
              {!showBeregnetFakturaBelopNesteAar && (
                <>
                  <Glyphicon glyph="plus-sign" />
                  <span style={{ marginLeft: "5px" }}>Vis detaljer for {new Date().getFullYear() + 1}</span>
                </>
              )}
              {showBeregnetFakturaBelopNesteAar && (
                <>
                  <Glyphicon glyph="minus-sign" />
                  <span style={{ marginLeft: "5px" }}>Skjul detaljer for {new Date().getFullYear() + 1}</span>
                </>
              )}
            </Button>
            {showBeregnetFakturaBelopNesteAar && (
              <>
                <div className="detaljer">
                  {beregnetFakturaBelop.fakturaBelopNesteAar.map(maaned => (
                    <div className="detaljelinje" key={maaned.maaned}>
                      <div style={{ width: "100px" }}>{maaned.maaned}</div>
                      <div style={{ width: "100px" }}>
                        {Intl.NumberFormat("nb-NO", { style: "currency", currency: "NOK" }).format(maaned.belop)}
                      </div>
                      <div>
                        {maaned.forklaring && (
                          <span className="forklaring">
                            <Glyphicon glyph="info-sign" />
                            {maaned.forklaring}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <div style={{ width: "100px", display: "inline-block" }}>
                    <strong>Sum</strong>
                  </div>
                  <div style={{ width: "100px", display: "inline-block" }}>
                    <strong>
                      {Intl.NumberFormat("nb-NO", { style: "currency", currency: "NOK" }).format(
                        Math.round(beregnetFakturaBelop.fakturaBelopNesteAar.reduce((total, m) => total + m.belop, 0))
                      )}
                    </strong>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </Alert>
  );
};

export default FakturaBeregning;
