import { find } from "lodash-es";
import PropTypes from "prop-types";
import { Row, Col, Panel } from "react-bootstrap";
import { BymForm, BymTextInput } from "components/Common/Forms";
import UgeDateInput from "components/Common/Forms/UgeDateInput";
import useBrukere from "hooks/useBrukere";

const getPurreNivaaName = faktura => {
  if (faktura.purreNivaa === "1") return "1. Purring";
  if (faktura.purreNivaa === "2") return "2. Purring";
  if (faktura.purreNivaa === "I") return "Inkasso";
  return "";
};

const FakturaDetails = ({ faktura }) => {
  const ordrelinjer = faktura.ordrelinjer || [];
  const updatedFaktura = { ...faktura, purreNivaaName: getPurreNivaaName(faktura) };

  const { brukere } = useBrukere();
  const lookupId = `bym${faktura.ansvarlig}`;

  // finn bruker basert på ansvarlig-id, eller vis bymident
  const ansvarligNavn = find(brukere, b => b.bymIdent == lookupId)?.navn || faktura.ansvarlig;

  return (
    <Panel className="">
      <BymForm values={updatedFaktura} id="fakturaDetails" readOnly>
        {faktura.tittel && (
          <Row>
            <Col md={12}>
              <BymTextInput controlId="tittel" label="Tittel" />
            </Col>
          </Row>
        )}
        <Row>
          <Col md={4}>
            <BymTextInput controlId="fakturaStatus.navn" label="Status" />
          </Col>
          <Col md={4}>
            <BymTextInput controlId="kontrakt.faktureringType.navn" label="Faktureringtype" />
          </Col>
          <Col md={4}>
            <BymTextInput controlId="ansvarlig" label="Ansvarlig" value={ansvarligNavn} />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <BymTextInput controlId="topptekst" readOnly label="Topptekst" />
          </Col>
          <Col md={4}>
            <BymTextInput controlId="fakturaType" label="Faktura type" />
          </Col>
          <Col md={4}>
            <BymTextInput controlId="itasKvittering" label="Itas kvittering id" />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <BymTextInput controlId="fakturaNummer" label="Fakturanummer" />
          </Col>
          <Col md={4}>
            <UgeDateInput controlId="fakturaDato" label="Fakturert dato" />
          </Col>
          <Col md={4}>
            <BymTextInput controlId="fakturertBelop" label="Fakturert beløp" />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <BymTextInput controlId="ordreNummer" label="Ordrenummer" />
          </Col>
          <Col md={4}>
            <BymTextInput controlId="inkassoKode" label="Inkassokode" />
          </Col>
          <Col md={4}>
            <BymTextInput controlId="purreNivaaName" label="Purrenivå" />
          </Col>
        </Row>
        <Row>
          {faktura.fakturaType === "kreditnota" && (
            <>
              <Col xs={4}>
                <BymTextInput controlId="kreditertFakturaId" label="Opprinnelig faktura" />
              </Col>
              <Col xs={4}>
                <BymTextInput controlId="prosjektnummer" />
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col md={4}>
            <UgeDateInput controlId="forfallDato" label="Forfall dato" />
          </Col>
          <Col md={4}>
            <UgeDateInput controlId="betaltDato" label="Betalt dato" />
          </Col>
          <Col md={4}>
            <UgeDateInput controlId="sistPurretDato" label="Sist purret dato" />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <BymTextInput controlId="kundenummer" label="Kundenummer" />
          </Col>
          <Col md={4}>
            <BymTextInput controlId="organisasjonsnummer" label="Organisasjonsnummer" />
          </Col>
          <Col md={4}>
            <BymTextInput controlId="fodselsnummer" label="Fødselsnummer" />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <UgeDateInput controlId="opprettetDato" label="Opprettet" />
          </Col>
          <Col md={4}>
            <BymTextInput controlId="opprettetAvNavn" label="Opprettet av" />
          </Col>
          <Col xs={4}>
            <BymTextInput controlId="sistEndretAvNavn" label="Sist endret av" />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="form-group">
              <br />
              <table className="simple-table">
                <thead>
                  <tr>
                    <th>Ordrelinje tekst</th>
                    <th>Kontonummer</th>
                    <th>Koststed</th>
                    <th>Antall</th>
                    <th>Pris</th>
                  </tr>
                </thead>
                {ordrelinjer.map(o => (
                  <tr style={{ color: "#555555", fontSize: "14px", margin: "4px 15px" }}>
                    <td>{o.tittel}</td>
                    <td>{o.kontonummerId}</td>
                    <td>{o.koststedId}</td>
                    <td>{o.antall}</td>
                    <td>{o.enhetspris.toFixed(2)}</td>
                  </tr>
                ))}
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <br />
            <h4>Fra kontrakt</h4>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <BymTextInput controlId="kontrakt.deresRef" label="Deres ref" />
          </Col>
        </Row>
      </BymForm>
      <br />
    </Panel>
  );
};

FakturaDetails.propTypes = {
  faktura: PropTypes.object
};

export default FakturaDetails;
