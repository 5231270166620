import { useEffect, useState } from "react";
import useActions from "store/useActions";
import * as brukerActions from "../actions/brukerAction";
import { useSelector } from "react-redux";
import brukereSelector from "selectors/brukereSelector";

export default function useBrukere(forceReload = false) {
  const [initialized, setInitialized] = useState(false);
  const { getBrukere } = useActions(brukerActions);

  const brukere = useSelector(state => brukereSelector(state));

  useEffect(() => {
    if (!initialized) {
      getBrukere(forceReload);
    }
    setInitialized(true);
  }, [getBrukere, forceReload, initialized]);

  return {
    brukere: brukere
  };
}
