import { getUser } from "auth/authTools";
import { map, find } from "lodash-es";

// for BymSelect: vis bare brukere som har bymident/samaccountname satt
export function brukereMedBymIdent(brukerliste: any) {
  const brukere = map(brukerliste, b => {
    return {
      value: transformBymIdentToAnsvarligId(b.bymIdent),
      navn: b.navn
    };
  })
    .filter(b => b.value)
    .sort((a, b) => {
      if (a.navn === b.navn) return 0;
      if (a.navn?.toLocaleLowerCase() > b.navn?.toLocaleLowerCase()) return 1;
      return -1;
    });

  return brukere;
}

export function getLoggedOnUser(brukerliste: any) {
  const currentUser = getUser();
  const bruker = find(brukerliste, b => currentUser?.profile.email == b.epost);

  return bruker;
}

export function transformBymIdentToAnsvarligId(bymIdent: string) {
  if (!bymIdent) return "";

  return bymIdent?.replace("bym", "");
}
